import { TrendingProductCard } from "@components/cards/product/trending";
import { TProduct } from "@components/types";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
    products: TProduct[];
    element?: React.ElementType;
    pageNumber?: number;
    setPageNumber?: any;
    isLoadMore?: boolean;
    isLoading?: boolean;
}

export const TrendingProductContainer: React.FC<Props> = ({ products, element, pageNumber, setPageNumber, isLoading, isLoadMore = true }) => {

    let RepeatElement = element == undefined || element == null ? TrendingProductCard : element;

    const { ref, inView } = useInView({
        threshold: 0,
        delay: 50
    });

    // Loader spinTransition
    const spinTransition = {
        loop: Infinity,
        ease: "easeInOut",
        duration: 1,
        repeat: Infinity
    };

    useEffect(() => {
        if (inView) {
            handleLoadMore();
        }
    }, [inView]);

    const handleLoadMore = () => {
        if (isLoadMore && pageNumber !== undefined) {
            setPageNumber(pageNumber + 1);
        }
    }

    return (
        <div className="flex flex-col gap-1 rounded-t-xl">
            {/* <div className="p-2 bg-neutral-800 text-neutral-50 font-semibold text-lg rounded-t-xl">{title}</div> */}
            <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 rounded-xl">
                {products && products.map((product: TProduct) => (
                    <RepeatElement product={product} key={product.id}></RepeatElement>
                ))}
            </div>

            {(isLoading == true ? <div className="flex h-44 w-full justify-center items-center">
                <div className="relative">
                    <motion.span className="block box-border rounded-full w-12 h-12 border-2 border-solid border-ternary border-t-brand"
                        animate={{ rotate: 360 }}
                        transition={spinTransition}
                    />
                </div>
            </div>
                :
                (isLoadMore ?
                    <div className="flex flex-col items-center p-2" ref={ref}>
                        <button disabled={!isLoadMore} className={!isLoadMore ? "white text-neutral active:bg-neutral-50 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 border border-solid border-gray-300 opacity-60" : "white text-neutral active:bg-neutral-50 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 border border-solid border-gray-300"} onClick={() => handleLoadMore()}>{!isLoadMore ? "No more products" : "Load More"}</button>
                    </div> : null))
            }
        </div>
    )
}