import React from "react";
import Image from "next/image";
import Link from "next/link";
import { TProductCategory } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from '@core/utils';
import { AiOutlineForward } from "react-icons/ai";


type Props = {
    element: TProductCategory;
    loading?: "lazy" | "eager"
};


export const ProductCategoryCard = ({ element, loading }: Props) => {

    return (
        <div className="flex flex-col max-w-xs bg-neutral-100 border border-solid border-neutral-300 rounded-xl overflow-hidden">
            <div className="flex grow justify-center min-h-32 w-full bg-neutral-50 overflow-hidden">
                <Link href={`/category/${element?.id}/${getSafeUrl(element?.name)}`}>
                    <Image
                        loader={sanityIoImageLoader}
                        src={element?.image || "https://jhattse.com/api/v1/file/?key=esangrah/UFVYFNUN-product-category.png"}
                        alt={element?.name}
                        width="200"
                        height="200"
                        className="w-full object-cover scale-100 hover:scale-105 focus:scale-105 ease-in duration-200"
                        loading={loading}
                    />
                </Link>
            </div>
            <div className="leading-tight grid gap-1 p-4">
                <div className="font-simple text-left overflow-hidden">
                    <p className="text-base text-neutral-700 text-ellipsis font-bold break-words line-clamp-1 md:line-clamp-none leading-tight">{element?.name}</p>
                    <p className="flex gap-1 text-neutral-500 text-sm font-bold"><span>{element?.total_products < 10 ? `${element?.total_products}` : `${Math.round(element?.total_products / 10) * 10}+`}</span> Products</p>
                </div>
            </div>
        </div>
    )
}