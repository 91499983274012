import { CartButton } from '@components/cartbutton';
import { CartButton as CartButtonV3 } from '@components/cartbutton/v3';
import { CartButton as CartButtonV4 } from '@components/cartbutton/v4';
import { TCartItem, TInventory, TProduct } from '@components/types';
import { cartState } from '@recoil/atoms/cart';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

type BtnProps = {
    product: TProduct;
    inventory: TInventory;
    mode: string;
    btnSize?: string;
    btnStyle?: string;
    Isvariant?: boolean;
    variantId?: number;
};

interface Props {
    product: TProduct;
    inventory?: TInventory;
    addToCartBag?: boolean;
    btnStyle?: string;
    BtnType?: React.ElementType;
}

export const AddToCart = ({
    product,
    inventory,
    addToCartBag = true,
    btnStyle,
    BtnType,
}: Props) => {
    const cart = useRecoilValue(cartState);
    const [localCart, setLocalCart] = useState<Map<string, TCartItem>>();
    const ButtonType = product?.variants?.length > 1 ? (BtnType == undefined ? CartButton : CartButtonV4) : CartButtonV4;

    useEffect(() => {
        setLocalCart(cart);
    }, [cart]);



    return (
        <>
            {/* {product?.variants?.length > 1 ? (
                <CartButtonV3
                    product={product}
                    inventory={inventory}
                    mode={''}
                    btnStyle={btnStyle}
                ></CartButtonV3>
            ) : ( */}
            <>
                <ButtonType
                    product={product}
                    inventory={inventory}
                    mode={''}
                    btnStyle={btnStyle}
                ></ButtonType>
            </>
            {/* )} */}

            {localCart == undefined ||
                localCart?.size == 0 ||
                addToCartBag == false ||
                localCart.get(inventory?.variant_id?.toString()) == undefined ? (
                <div></div>
            ) : (
                <Link href="/cart">
                    <div className="flex bg-sky-100 p-1.5 lg:px-3 px-2 flex-row text-sm lg:rounded-xl rounded-lg hover:bg-sky-200">
                        <div className="flex flex-row">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#1599D1"
                                className="lg:w-6 lg:h-6 w-5 h-5 self-center"
                                viewBox="0 0 448 512"
                            >
                                <path d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 96c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24zm200-24c0 13.3-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24z" />
                            </svg>
                        </div>
                    </div>
                </Link>
            )}
        </>
    );
};
