import { ProductCategoryCard } from "@components/cards";
import { TProductCategory } from "@components/types";
import React from "react";

interface Props {
    categories: TProductCategory[];
    element?: React.ElementType;
    customStyle?: string;
}

export const ProductCategoryContainer: React.FC<Props> = ({ categories, element, customStyle }) => {

    let RepeatElement = element == undefined || element == null ? ProductCategoryCard : element;
    return (
        <div className={`flex flex-col gap-1 rounded-t-xl px-4 ${customStyle ? customStyle : ""}`}>
            <div className="grid grid-flow-row-dense grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-4 rounded-t-xl">
                {categories && categories.map((category: TProductCategory) => (
                    <RepeatElement element={category} key={category.id}></RepeatElement>
                ))}
            </div>
        </div>
    )
}