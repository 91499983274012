import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Modal, Input, notification } from 'antd';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { FiPlus, FiEdit2, FiLoader } from 'react-icons/fi';

import { addStoreReviews, getMyStoreReview } from '@api/rating';
import { TAddReviews } from '@components/types';
import { requestLogin } from '@core/utils';

interface StoreReviewProps {
  store_id: number;
}

export default function StoreReview({
  store_id
}: StoreReviewProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [review, setReview] = useState<TAddReviews>({
    comment: '',
    rating: 0
  });

  const router = useRouter();
  const [api, contextHolder] = notification.useNotification();

  async function fetchMyStoreReview() {
    const review = await getMyStoreReview(store_id);
    setReview({
      store_id,
      id: review?.id,
      rating: review?.rating,
      comment: review?.comment,
    });
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function handleCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
    setReview({ ...review, comment: event.target.value });
  }

  function handleAddReview(event: React.FormEvent) {
    event.preventDefault();

    if (!review.comment) {
      return api.error({
        message: 'Please add a review.',
        placement: 'bottomRight'
      });
    }

    if (review.rating === 0) {
      return api.error({
        message: 'Please select a rating.',
        placement: 'bottomRight'
      });
    }

    setIsLoading(true);
    const response = addStoreReviews(review);
    response
      .then((review) => {
        api.success({
          message: 'Review submitted',
          placement: 'bottomRight'
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          requestLogin(router.asPath);
        }
      })
      .finally(() => {
        setIsLoading(false);
        toggleModal();
      })
  }

  useEffect(() => {
    fetchMyStoreReview();
  }, [store_id, isLoading])

  return (
    <>
      {contextHolder}

      <button onClick={toggleModal} className="flex items-center gap-1 hover:cursor-pointer">
        {review?.id ? (
          <>
            <FiEdit2 className="w-4 h-4 text-brand-500" />
            <span className="text-sm text-brand-500 font-manrope font-semibold">
              Edit Review
            </span>
          </>
        ) : (
          <>
            <FiPlus className="w-4 h-4 text-brand-500" />
            <span className="text-sm text-brand-500 font-manrope font-semibold">
              Add a Review
            </span>
          </>
        )}
      </button>

      <Modal
        title={review?.id ? 'Edit Review' : 'Add Review'}
        open={isModalOpen}
        onCancel={toggleModal}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        destroyOnClose
      >
        <form
          onSubmit={handleAddReview}
          className='pt-2.5 flex flex-col gap-4'
        >
          <Input
            value={review.comment}
            onChange={handleCommentChange}
            placeholder='Review'
            size='middle'
          />

          <div className="flex items-center">
            {[1, 2, 3, 4, 5].map((num) => (
              <button
                type="button"
                onClick={() => setReview({ ...review, rating: num })}
                className='cursor-pointer'
              >
                {review.rating >= num ? (
                  <FaStar className='w-5 h-5 text-yellow-400' />
                ) : (
                  <FaRegStar className='w-5 h-5 text-yellow-400' />
                )}
              </button>
            ))}
          </div>

          <button
            type='submit'
            disabled={isLoading}
            className='w-fit h-9 px-4 flex items-center gap-2 text-sm text-white font-manrope rounded-lg transition-all duration-200 bg-brand-500 hover:opacity-80 hover:cursor-pointer'
          >
            {isLoading && <FiLoader className='w-4 h-4 text-white animate-spin' />}
            {isLoading ? 'Loading' : 'Submit'}
          </button>
        </form>
      </Modal>
    </>
  )
}
