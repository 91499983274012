import { Star } from '@components/star';
import { TProduct, TVariant } from '@components/types';
import Image from 'next/image';
import Link from 'next/link';
import {
  getFirst,
  getImageObject,
  getImageUrl,
  getLength,
  getSafeUrl,
  humanizeCurrency,
  sanityIoImageLoader,
  trimToLength,
} from '@core/utils';
import { AddToCart } from '@components/addtocart';
import { useState } from 'react';
import {
  getCombinedName,
  inventoryByVariantId,
} from '@components/variant/variantSelector';

type Props = {
  product: TProduct;
};

export const SmallProductCard = ({ product }: Props) => {
  const [variant, setVariant] = useState<TVariant>(
    product?.variants?.find(
      (variant) =>
        getFirst(inventoryByVariantId(variant, product))?.is_available == true
    ) as TVariant
  );

  return (
    <div className="flex flex-row gap-4  bg-neutral-50 rounded-md border border-solid border-neutral-300 overflow-hidden">
      <div className="flex items-start justify-start">
        <div className="relative flex justify-center max-h-40 h-full">
          <Link href={`/product/${product.id}/${getSafeUrl(product.name)}`}>
            <Image
              loader={sanityIoImageLoader}
              src={getImageObject(product.images)?.url}
              alt={
                getImageObject(product.images)?.description ||
                product.name ||
                'Product'
              }
              width="140"
              height="140"
              className="aspect-square h-full"
            />
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-2 justify-between pr-2 py-3">
        <div className="flex flex-col gap-1 leading-tight font-manrope overflow-hidden">
          <Link href={`/product/${product.id}/${getSafeUrl(product?.name)}`}>
            <div className="font-semibold text-ellipsis md:text-base text-sm text-neutral-900 font-manrope line-clamp-1">
              {trimToLength(getCombinedName(product, variant?.id), 40)}
            </div>
          </Link>
          <div className="flex gap-2 items-center lg:text-base text-sm">
            {trimToLength(variant?.name, 40) && (
              <div className="bg-success-100 border border-solid border-success-300 rounded flex items-center justify-center px-1 py-0.5 text-neutral-900 font-medium line-clamp-1">
                {trimToLength(variant?.name, 40)}
              </div>
            )}
            {product?.stats?.rating_overall ? (
              <div>
                <Star rating={product?.stats?.rating_overall} />
              </div>
            ) : (
              <></>
            )}
          </div>
          {getLength(product?.inventories) > 0 ? (
            <div className="price">
              <span className="text-neutral-900 font-semibold lg:text-lg text-sm">
                {humanizeCurrency(
                  getFirst(product?.inventories)?.price || product?.mrp
                )}
              </span>
              {/* <span className="font-bold text-success-300">{product.offer}</span> */}
            </div>

          ) : (
            <p className="text-error-400">Unavailable</p>
          )}
        </div>
        {getLength(product?.inventories) > 0 ? (
          <div className="flex flex-row gap-4 items-center w-32">
            <AddToCart
              product={product}
              inventory={getFirst(product?.inventories)}
            ></AddToCart>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
