import Image from 'next/image';
import Link from 'next/link';
import { MdOutlineLocationOn } from "react-icons/md";
import { TStore } from '@components/types';
import { Star } from '@components/star';
import { getSafeUrl, sanityIoImageLoader } from '@core/utils';


interface Props {
    store: TStore;
    hideRating?: boolean;
    loading?: "lazy" | "eager"
}


export const SmallStoreCard = ({ store, hideRating, loading }: Props) => {
    const localDistance = store?.address?.distance;
    return (
        <div className="w-full md:max-w-[420px] flex flex-row bg-neutral-50 rounded-md overflow-hidden h-full" >
            <div className="flex justify-center relative" >
                <Link href={`/store/${store?.id}/${getSafeUrl(store?.name)}`} className="flex justify-center items-center">
                    <Image
                        loader={sanityIoImageLoader}
                        src={store?.image || "https://cdn.jhattse.com/public/consumer/placeholder/store.png"}
                        alt={store?.name}
                        width="140"
                        height="140"
                        className="border rounded-l-lg aspect-square h-full"
                        loading={loading}
                    />
                </Link>
            </div>
            <div className="flex flex-col justify-between leading-tight md:pl-4 p-2 w-full rounded-r-md overflow-hidden font-manrope">
                <div>
                    <Link href={`/store/${store?.id}/${getSafeUrl(store?.name)}`}>
                        <div className="font-semibold text-base line-clamp-2 text-neutral-900 leading-tight">{store?.name}</div>
                    </Link>
                    <p className="pb-1"><span className="md:text-sm text-xs font-medium text-neutral-500">{store?.category?.name}</span></p>
                </div>
                <div>
                    {hideRating !== true && (store?.stats?.rating_overall ? <div className="pb-1">
                        <Star rating={store?.stats?.rating_overall} />
                    </div> :
                    <div className="pb-1">
                        <div className="text-sm text-error-300 font-manrope font-medium">No Ratings</div>
                    </div>)
                    }
                    {(store?.is_pickup || store?.is_delivery) && <div className="flex flex-row gap-2 font-manrope pb-1">
                        {store?.is_pickup && <button className="font-manrope bg-brand-100 md:text-sm text-xs text-bannerText font-semibold px-2 py-1 rounded-sm whitespace-nowrap" >PICKUP</button>}
                        {store?.is_delivery && <button className="font-manrope bg-business-100 md:text-sm text-xs text-bannerText font-semibold px-2 py-1 rounded-sm whitespace-nowrap" >DELIVERY</button>}
                    </div>}
                    <div className="flex flex-row md:text-sm text-xs items-center">
                        <Link href={`http://www.google.com/maps/place/${store?.address?.latitude},${store?.address?.longitude}`} target="_blank" className="flex items-center gap-0.5 font-semibold"><div className="flex items-center text-brand-500"><MdOutlineLocationOn className="text-sm font-semibold" /></div><p className="text-xs text-brand-500 line-clamp-1 overlow-ellipsis">{store?.address?.street_name}, {store?.address?.city?.name}{(localDistance != undefined && localDistance < 10000) && <><span className="text-neutral-700"> | </span><span className="text-xs text-brand-500 font-semibold whitespace-nowrap">{localDistance < 1000 ? localDistance.toFixed(0).toString() + " m" : (localDistance < 10000 ? (localDistance / 1000).toFixed(1).toString() + " km" : null)}</span></>}</p></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}