import React from "react";
import Image from "next/image";
import Link from "next/link";
import { TBrand } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from "@core/utils";

type Props = {
  product: TBrand;
  loading?: "lazy" | "eager";
};

export const BrandCard: React.FC<Props> = ({ product, loading }) => {
  return (
    <div
      key={product.id}
      className="flex flex-col bg-neutral-50 border border-solid border-neutral-200 rounded-xl shrink-0 md:w-40 w-40 h-56"
    >
      <div className="flex h-36">
        <Link href={`/brand/${product.id}/${getSafeUrl(product.name)}`}>
          <Image
            loader={sanityIoImageLoader}
            src={product?.logo || product?.image || "https://cdn.jhattse.com/public/consumer/placeholder/brand.png"}
            className="rounded-t-xl h-36 object-cover max-w-full"
            alt={product.name}
            width="360"
            height="250"
            loading={loading}
          />
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center font-semibold grow gap-0.5 p-2 leading-tight text-center">
        <h3 className="md:text-base text-sm font-manrope text-neutral-700 text-ellipsis break-words line-clamp-2 md:line-clamp-none">
          {product.name}
        </h3>
        <span className="md:text-sm text-xs font-manrope text-neutral-500 text-ellipsis break-words line-clamp-1 md:line-clamp-none leading-tight">{product?.description}</span>
      </div>
    </div>
  );
};
