import React from "react";
import { TInventory, TProduct } from "@components/types";
import { useRecoilState } from "recoil";
import { cartState } from "@recoil/atoms";
import Link from "next/link";
import { variantState } from "@recoil/atoms/variant";

type Props = {
  product: TProduct;
  inventory: TInventory;
  mode: string;
  btnSize?: string;
  btnStyle?: string;
};

export const CartButton = ({
  product,
  inventory,
  mode,
  btnSize,
  btnStyle,
}: Props) => {
  const [cart, setCart] = useRecoilState(cartState);
  const [Variant, setVariant] = useRecoilState(variantState);

  const onClickAdd = (product: TProduct) => {
    if (product?.variants?.length > 1) {
      setVariant({
        Isvariant: true,
        product: product,
        showModal: true,
        quantity: 0,
        inventory: inventory
      });
    }
    else if (inventory?.addons?.length > 0) {
      setVariant({ Isvariant: true, product: product, showModal: true, quantity: 1 })
    }
  };
  return inventory?.external_link?.length > 0 ? (
    <div
      className={`flex md:justify-start justify-center items-center max-w-lg min-w-full text-neutral-50`}
    >
      <Link
        onClick={() => {
          window?.fbq('trackCustom', 'BuyNowClicked', {
            link: inventory?.external_link,
            content_name: `${product?.name || inventory?.product?.name}`,
            content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
            content_ids: [`${product?.id || inventory?.id}`],
            content_type: 'product',
            value: inventory?.price || product?.mrp,
            currency: 'RS'
          });
        }}
        href={inventory?.external_link}
        className="lg:h-8 h-7 border-[2px] bg-blue-300 md:px-9 px-2 hover:opacity-80 focus:opacity-80 lg:text-lg md:text-base text-sm font-semibold whitespace-nowrap select-none md:rounded-md rounded flex justify-center items-center font-manrope"
      >
        BUY NOW
      </Link>
    </div>
  ) : (
    <div
      className={`flex md:justify-start justify-center items-center max-w-lg min-w-full`}
    >
      <button
        onClick={() => onClickAdd(product)}
        className="lg:h-8 h-7 border border-solid bg-brand_indigo text-white shadow-xl lg:text-lg md:text-base text-sm text-center text-black font-semibold w-full whitespace-nowrap select-none md:rounded-md rounded font-manrope cursor-pointer"
      >
        ADD
      </button>
    </div>
  );
};
